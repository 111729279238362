

























.header {
  height: 64px;
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
}
