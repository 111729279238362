







































.main {
  margin-left: 86px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  margin: 32px;
  height: 100%;
  flex: 1 1 auto;
}
