@import '~ant-design-vue/dist/antd.css';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

.content {
  background: #FFF;
  padding: 16px 20px;
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;

  &--nopadding {
    padding: 0;
  }
}

.Vue-Toastification__close-button {
  padding-left: 0 !important;
}


.vgt-wrap {
  background: #FFFFFF;
  z-index: 1;

  .vgt-inner-wrap {
    border-radius: unset;
    box-shadow: unset;
  }

  .primary-table {
    width: 100%;
    font-size: 14px !important;
    line-height: 16px;
    font-weight: 400;

    thead {
      th {
        padding: 24px 22px 12px 10px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        color: #424447;

        &.sortable {
          position: relative;

          button {
            position: absolute;
            top: 45%;
          }

          span {
            margin-right: 4px;
          }
        }

        &:first-child {
          padding: 24px 22px 12px 24px;

          span {
            margin-left: 17px;
          }
        }

        &:last-child {
          padding-right: 24px;
        }

        &.filter-th {
          padding: 0 5px 16px 5px;

          &:first-child {
            padding-left: 24px;
          }

          &:last-child {
            padding-right: 24px;
          }
        }

        span {
          margin-left: 12px;
        }

        button {
          position: relative;
          width: 14px;
          height: 18px;
          display: inline-block;
          top: -6px;
          outline: unset;

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translate(-50%, -50%) rotateX(0deg);
            width: 8px;
            height: 18px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L4 4.5L7 1.5' stroke='%23424447' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%) rotateX(180deg);
            width: 8px;
            height: 18px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L4 4.5L7 1.5' stroke='%23424447' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }

        &.sorting-asc {
          button:after {
            background-image: unset;
          }
          button:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotateX(180deg);
            width: 8px;
            height: 7px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L4 4.5L7 1.5' stroke='%23424447' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }

        &.sorting-desc {
          button:before {
            background-image: unset;
          }
          button:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotateX(0deg);
            width: 8px;
            height: 7px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L4 4.5L7 1.5' stroke='%23424447' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }

      .vgt-input,
      .vgt-select {
        width: 100%;
        min-height: 40px;
        max-height: 40px;
        padding: 6px 16px;
        background-color: #F4F7FC;
        border: 1px solid #F4F7FC;
        border-radius: 4px;
        color: #9C9EA3;
        transition: all 200ms ease;

        &::placeholder {
          color: #9C9EA3;
        }

        &:focus {
          outline: none;
          border-color: #00ADFF;
          box-shadow: unset;
        }

        &:hover {
          border-color: #00ADFF;
        }
      }

      .vgt-select {
        -moz-appearance: none;
        -webkit-appearance: none;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #ECECEE;

        &:first-child {
          border-top: 1px solid #ECECEE;
        }

        &:last-child {
          border-bottom: none;

          div.relative {
            padding-right: 20px;
          }
        }
      }

      td {
        padding: 17px 16px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #424447;

        &:first-child {
          padding: 24px 10px 17px 24px;
        }

        &:last-child {
          padding-right: 24px;
        }

        div {
          margin-left: 6px;
        }

        &:first-child {
          div {
            margin-left: 16px;
          }
        }
      }
    }

    &__actions-dropdown {
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      color: #1D293F;

      i {
        display: block;
        vertical-align: 0;
      }
    }

    &__dropdown-menu {

      .ant-dropdown-menu-item > a {
      }

      a {
        display: flex;
        align-items: center;
      }

      i {
        margin-right: 8px;
      }

      svg {
        color: #B1BACB;
      }
    }
  }

  .vgt-wrap__footer {
    padding: 20px 48px 32px 48px;
  }

  .vgt-checkbox-col {
    padding-left: 24px !important;
    padding-right: 0 !important;
  }

  .vgt-selection-info-row {
    background-color: #EFF2F8;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    padding: 0 0 0.5rem 0;
    position: absolute;
    top: 85px;
    right: 32px;

    a {
      padding: 0 1rem;
    }
  }

  .vgt-loading {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;

    &__content {
      background-color: white;
      padding: 10px 20px;
      border-radius: 3px;
    }
  }
}

.primary-table__dropdown-menu {
  a {
    display: flex;
    align-items: center;
  }

  .anticon {
    margin-right: 12px;
    color: #B1BACB;

    &.anticon-delete {
      color: #F2323F;
    }
  }
}
