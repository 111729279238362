
















































































































.left-menu {
  background: #FFF;
  height: 100vh;
  position: fixed;
  flex-direction: column;
  display: flex;
  padding: 6px 3px;
  width: 86px;
  transition: all .2s ease-in-out;
  border-right: 1px solid #eeeeee;
  z-index: 10;

  .menu-item {
    display: flex;
    justify-content: flex-start;
    height: 40px;
    padding: 6px 24px;
    margin-bottom: 10px;

    > :last-child {
      padding-left: 22px;
      color: #000;
    }
  }

  .logout {
    padding: 6px 24px;
    display: flex;
    justify-content: flex-start;
    margin-top: auto;
    color: red;
    cursor: pointer;
    height: 40px;

    > :last-child {
      padding-left: 22px;
      color: #000;
      margin-top: -3px;
    }
  }

  .hideIfToggle {
    opacity: 0;
    transition: all .2s ease-in-out;
  }

  &.open {
    width: 300px;

    .hideIfToggle {
      opacity: 1;
      transition-delay: .2s;
    }
  }
}
